import React from "react";
import { notification } from "antd";

//  type   success / info / warning / error
// getWordClose getword kapıyor
//getword çalışınca düzeltilmeli
const notificationMessage = (
  type,
  description,
  getWordClose,
  placement = "bottomLeft"
) => {
  if (
    description == "SystemFailureSeeLogsForDetail" ||
    description == "BadRequest"
  ) {
    return;
  }

  const information = titleAndColor(type);
  notification[type || "success"]({
    message: (
      <span style={{ color: information.color }}>{information.titleKey}</span>
    ),
    description: getWordClose
      ? description
      : description?.messages?.[0]?.description ||
        (description && typeof description !== "object"
          ? description
          : false) ||
        information.descriptionKey,
    placement: placement,
  });
};

const titleAndColor = (type) => {
  switch (type) {
    case "info":
      return {
        color: "#1890ff",
        titleKey: "Info",
        descriptionKey: "",
      };
    case "warning":
      return {
        color: "#faad14",
        titleKey: "Warning",
        descriptionKey: "",
      };
    case "error":
      return {
        color: "#ff4d4f",
        titleKey: "Error",
        descriptionKey: "",
      };

    default:
      return {
        color: "#4caf50",
        titleKey: "Success",
        descriptionKey: "",
      };
  }
};

export default notificationMessage;
